<template>
    <div
      class="row g-6"
    >
      <div
        class="col-xl-4"
      >
        <div class="card" style="width: 700px">
          <div class="card-body d-flex flex-column">
            <!--begin::Heading-->
            <div class="text-center">
              <!--begin::Title-->
              <div class="card-label fw-bolder fs-2">{{ $t("modals.pollster.startPoll.title") }}</div>
              <span class="text-muted fs-7 mb-2"
                >{{ $t("modals.pollster.startPoll.desc") }}</span
              >
              <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Form-->
            <Form
              id="kt_account_profile_details_form"
              class="form"
              :validation-schema="addStaffForm"
              @submit="submit"
            >
              <!--begin::Card body-->
              <div class="card-body p-9 ps-6 pb-1">
                <div class="row mb-0">
                  <div class="col-md-12">
                    <!--begin::Input group-->
                    <div class="fv-row mb-10">
                      <!--begin::Label-->
                      <label
                        class="form-label fs-6 fw-bolder text-dark required"
                        >{{ $t("modals.pollster.startPoll.name") }}</label
                      >
                      <!--end::Label-->
  
                      <!--begin::Input-->
                      <Field
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        name="name"
                        autocomplete="off"
                        :placeholder="$t('modals.pollster.startPoll.enterName')"   
                      />
                      <!--end::Input-->
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="name" />
                        </div>
                      </div>
                    </div>
                    <!--end::Input group-->
                  </div>
                </div>
                <div class="row mb-0">
                  <div class="col-md-12">
                    <!--begin::Input group-->
                    <div class="fv-row mb-10">
                      <!--begin::Label-->
                      <label
                        class="form-label fs-6 fw-bolder text-dark required"
                        >{{ $t("modals.pollster.startPoll.surname") }}</label
                      >
                      <!--end::Label-->
  
                      <!--begin::Input-->
                      <Field
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        name="surname"
                        autocomplete="off"
                        :placeholder="$t('modals.pollster.startPoll.enterSurname')"   
                      />
                      <!--end::Input-->
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="surname" />
                        </div>
                      </div>
                    </div>
                    <!--end::Input group-->
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <!--begin::Submit button-->
                  <button
                    type="submit"
                    id="kt_account_profile_details_submit"
                    ref="submitButton"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">{{ $t("common.button.start") }}</span>
                    <span class="indicator-progress">
                      {{ $t("common.pleaseWait") }}
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span>
                    </span>
                  </button>
                  <!--end::Submit button-->
                </div>
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
  
            <!--end::Button-->
          </div>
          <!--end::Card body-->
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted } from "vue";
  import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import * as Yup from "yup";
  import { useI18n } from "vue-i18n";
  import { useRouter } from "vue-router";
  
  export default defineComponent({
    name: "start-poll",
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    setup() {
      const { t } = useI18n();
      const router = useRouter();
  
      //Create form validation object
      const addStaffForm = Yup.object().shape({
        name: Yup.string().required(t("validators_error.required")).label("Name"),
        surname: Yup.string()
          .required(t("validators_error.required"))
          .label("Surname"),
      });
  
      const submit = (values: any) => {
        console.log(values);
        router.push({ path: `/pollsterUser` });
      };
  
      onMounted(() => {
        setCurrentPageTitle(t("modals.pollster.startPoll.title"));
      });
  
      return {
        addStaffForm,
        submit,
      };
    },
  });
  </script>
  