
  import { defineComponent, onMounted } from "vue";
  import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import * as Yup from "yup";
  import { useI18n } from "vue-i18n";
  import { useRouter } from "vue-router";
  
  export default defineComponent({
    name: "start-poll",
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    setup() {
      const { t } = useI18n();
      const router = useRouter();
  
      //Create form validation object
      const addStaffForm = Yup.object().shape({
        name: Yup.string().required(t("validators_error.required")).label("Name"),
        surname: Yup.string()
          .required(t("validators_error.required"))
          .label("Surname"),
      });
  
      const submit = (values: any) => {
        console.log(values);
        router.push({ path: `/pollsterUser` });
      };
  
      onMounted(() => {
        setCurrentPageTitle(t("modals.pollster.startPoll.title"));
      });
  
      return {
        addStaffForm,
        submit,
      };
    },
  });
  